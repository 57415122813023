import Bugsnag from '@bugsnag/js';
import posthog from 'posthog-js';

let started = false;

export const notify = (...args: Parameters<typeof Bugsnag.notify>) => {
    if (['production', 'preview'].includes(process.env.NEXT_PUBLIC_VERCEL_ENV || '') === false) {
        return;
    }
    if (!started) {
        Bugsnag.start({
            apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY || '',
            appType: 'browser',
            appVersion: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
            releaseStage: process.env.NEXT_PUBLIC_VERCEL_ENV,
            plugins: [],
            onError: (event) => {
                try {
                    event.addMetadata('PostHog', {
                        sessionReplayURL: posthog.get_session_replay_url({
                            withTimestamp: true,
                        }),
                    });
                } catch {}
            },
        });
        started = true;
    }
    Bugsnag.notify(...args);
};

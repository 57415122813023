'use client';

import { useEffect, useMemo } from 'react';
import Container from '../components/Container';
import styles from '../pages/404.module.scss';
import { CademyError } from '@shared/domain-shared';
import Image from 'next/image';
import { notify } from '../services/bugsnag';

export default function ErrorPage({ error }: { error: unknown }) {
    const cademyError = useMemo(() => {
        return CademyError.fromUnknown(error);
    }, [error]);

    useEffect(() => {
        const notifiableError = error instanceof Error ? error : new Error(String(error));
        notify(notifiableError, (event) => {
            event.severity = 'error';
        });
    }, [error]);

    return (
        <Container>
            <div className={styles.innerContainer}>
                <div>
                    <h1>Oops something went wrong</h1>

                    <h2>{cademyError.title}</h2>
                    {cademyError.detail ? <p>{cademyError.detail}</p> : null}

                    <h2>What can I do?</h2>
                    <ul>
                        <li>Try reloading the page and trying the action again.</li>
                        <li>
                            If you need help you can{' '}
                            <a href="mailto:support@cademy.io">reach out to our support team</a> to
                            report the issue.
                        </li>
                    </ul>
                </div>
                <div className={styles.image_container}>
                    <Image src="/404-owl.png" alt="oops" width={250} height={250} />
                </div>
            </div>
        </Container>
    );
}
